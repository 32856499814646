<template>
  <div>
    <van-dialog v-model="addAccountShow" :showConfirmButton="false" className="dialog">
      <div class="flex just-end">
        <img :src="closePng" alt="" class="img20" @click="addAccountShow = false" />
      </div>
      <div class="text-center">
        <p class="c-333333 rem36">支持的银行卡</p>
        <p class="rem26 mt12 c-979797">限额仅供参考，请以还款时显示的限额为准</p>
        <van-radio-group v-model="bank">
          <div v-for="(item, index) in backList" :key="index">
            <van-radio :name="item">
              <div class="flex align-center p-t-b10">
                <!-- <p class="mr16"><img :src="item.img" alt="" class="backImg" /></p> -->
                <div class="text-left">
                  <p class="rem30 c-333333 fNormal">{{ item.bankName }}</p>
                  <p class="rem26 c-979797 mt3">
                    单笔限额{{ item.maxBankRestrictMoney }}元，单日限额{{
                      item.bankRestrictAmountEachDay
                    }}元
                  </p>
                </div>
              </div>
            </van-radio>
          </div>
        </van-radio-group>
        <div style="height: 1rem"></div>
      </div>
      <div class="mt24 sure-btn">
        <van-button round block type="info" @click="addAccountFn">确认</van-button>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { Dialog, RadioGroup, Radio } from 'vant'
Vue.use(Dialog)
Vue.use(RadioGroup)
Vue.use(Radio)

import { mapState } from 'vuex'

import { getBackListApi } from '@/apis/credit/index'

export default {
  props: {
    payRouter: {
      type: String,
      default: '',
    },
    backNameFn: {
      type: Function,
    },
  },
  data() {
    return {
      addAccountShow: false, // 添加还款账户
      closePng: require('@/assets/images/borrow/close.png'),
      backList: [],
      bank: {},
    }
  },
  watch: {
    addAccountShow() {
      if (this.addAccountShow) {
        this.getBackList()
      }
    },
  },
  computed: {
    ...mapState({
      businessType: (state) => state.common.businessType,
      merchantRoute: (state) => state.borrow.merchantRoute,
    }),
  },
  methods: {
    getBackList() {
      getBackListApi({
        bindType: this.payRouter,
        businessCode: this.businessType,
        merchantRoute: this.merchantRoute,
      }).then((res) => {
        if (res.code === 200) {
          this.backList = res.data || []
        }
      })
    },
    addAccountFn() {
      this.$emit('backNameFn', this.bank)
      this.addAccountShow = false
      this.bank = {}
    },
  },
}
</script>
<style lang="less" scoped>
.dialog {
  padding: 0.44rem 0.2rem 0.44rem 0.44rem;
  width: 6rem;
  max-height: 9rem;
}
.c-979797 {
  color: #979797;
}
.backImg {
  width: 0.62rem;
  height: 0.6rem;
}
/deep/ .van-radio {
  border-bottom: 1px solid #e6e6e6;
}
.van-dialog__content {
  max-height: 9rem;
}
.text-center {
  max-height: 7.8rem;
  overflow: auto;
}
.sure-btn {
  position: fixed;
  bottom: 0.2rem;
  width: 90%;
  left: 5%;
  z-index: 9999;
}
</style>

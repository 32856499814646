<template>
  <div class="main-container">
    <span class="rem26 borrow-money">姓名</span>
    <van-field label-width="0" v-model="maskName" readonly />
    <div class="mt20">
      <span class="rem26 borrow-money">身份证号</span>
      <van-field label-width="0" v-model="userInfo.maskCardCode" readonly />
    </div>
    <van-field
      label-width="0"
      class="mt40"
      v-model="info.cardNo"
      type="number"
      placeholder="请输入本人银行卡号"
    >
      <template #extra>
        <van-uploader :after-read="handleAfterRead">
          <img :src="cameraIcon" class="img26" alt="" />
        </van-uploader>
      </template>
    </van-field>
    <van-field
      label-width="0"
      class="mt40"
      readonly
      v-model="info.bankName"
      placeholder="请选择银行名称"
    >
      <template #extra>
        <div class="flex align-center" @click="showBack">
          <img :src="noteIcon" class="img24" alt="" />
          <p class="rem26 c-4D7BFE back">支持银行</p>
        </div>
      </template>
    </van-field>
    <van-field
      label-width="0"
      type="digit"
      class="mt40"
      v-model="info.phoneNo"
      maxlength="11"
      placeholder="请输入银行卡预留手机号"
    >
    </van-field>
    <div class="mt40">
      <van-button round block type="info" @click="checkBankCode" :disabled="!writeComplate">
        下一步
      </van-button>
    </div>
    <back-list-info
      ref="backListInfo"
      @backNameFn="backNameFn"
      :payRouter="bindType"
    ></back-list-info>

    <van-dialog
      v-model="dialogFlag"
      title="验证码获取"
      :show-confirm-button="false"
      class="confirm-dialog"
    >
      <div class="close" @click="dialogFlag = false">X</div>
      <div style="padding: 20px 30px">
        <van-field
          v-model="phoneVerifyCode"
          type="digit"
          placeholder="请输入短信验证码"
          class="mt20"
        >
          <template #extra>
            <span class="rem28 c-4D7BFE" @click="getCode" v-if="countDown == 0">
              {{ codetext }}
            </span>
            <span class="rem28 c-4D7BFE" v-else>{{ countDown }}s</span>
          </template>
        </van-field>
      </div>
      <van-button @click="handleConfirm" class="confirm-btn" type="info">确认</van-button>
    </van-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Cell, Button, Uploader, Dialog, Toast } from 'vant'

import backListInfo from '@/components/backlist-info.vue'
import {
  getBankCardNumberByOrc,
  getUserInfo,
  getBankChangeVerifyApi,
  updateBankCardApi,
  uploadFileNewApi,
  ocrBankCardNoNewApi,
} from '@/apis/userCenter/index'
import { getBackListApi } from '@/apis/credit/index'
import { getUserBindCardListApi } from '@/apis/repayment/index'
import { Decrypt, Encrypt } from '@/utils/crypto'
import { merchantRouteApi } from '@/apis/borrow/index'
import { mapState } from 'vuex'

Vue.use(Field).use(Cell).use(Button).use(Uploader).use(Dialog)

export default {
  components: {
    backListInfo,
  },
  data() {
    return {
      userInfo: {},
      info: {
        cardNo: '',
        phoneNo: '',
        bankName: '',
        bankCode: '',
      },
      cameraIcon: require('@/assets/images/borrow/camera-icon.png'),
      noteIcon: require('@/assets/images/borrow/note-icon.png'),
      codePng: require('@/assets/images/login/code.png'),
      dialogFlag: false,
      phoneVerifyCode: '',
      codetext: '获取验证码',
      countDown: 0,
      userBankCardList: [],
      maskName: '',
      bindType: '',
      bankInfo: {}, // 银行卡信息
      bankList: [],
      merchantRoute: '',
      myLoan: {},
    }
  },
  created() {
    this.myLoan = this.$store.state.repayment.loan;
    this.getUserBindCardListFn()
    this.getUserInfoFn()
  },
  computed: {
    writeComplate() {
      return this.info.cardNo && this.info.phoneNo && this.info.bankName
    },
    ...mapState({
      businessType: (state) => state.common.businessType,
    }),
  },
  methods: {
    // 获取用户信息
    getUserInfoFn() {
      getUserInfo()
        .then((res) => {
          const {
            data: { maskCardCode, realName, idCard, userCardInfoList },
          } = res
          this.userBankCardList = userCardInfoList
          this.maskName = this.handleMaskName(realName)
          this.userInfo = { maskCardCode, realName, idCard: Decrypt(idCard) }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取绑卡列表
    getUserBindCardListFn() {
      let param = {bindType: this.myLoan.daishouMerNo};
      getUserBindCardListApi(param).then((res) => {
        const { bindType } = res.data[0]
        this.bindType = bindType
        if (this.bindType) this.merchantRouteFn()
      })
    },
    // 获取银行卡信息
    getUserBindCardList() {
      let param = {bindType: this.myLoan.daishouMerNo};
      getUserBindCardListApi(param).then((res) => {
        // eslint-disable-next-line prefer-destructuring
        this.bankInfo = res.data[0]
        this.$store.commit('bankFn', this.bankInfo)
      })
    },
    // 获取银行卡列表
    getBackList() {
      getBackListApi({
        bindType: this.bindType,
        businessCode: this.businessType,
        merchantRoute: this.merchantRoute,
      }).then((res) => {
        this.bankList = res.data
      })
    },
    // 获取merchantRoute
    merchantRouteFn() {
      merchantRouteApi({
        companyCode: this.bindType,
        businessType: this.businessType,
      }).then((res) => {
        if (res.code == 200) {
          this.$store.commit('SET_MERCHANT_ROUTE', res.data)
          this.merchantRoute = res.data
          if (this.merchantRoute) this.getBackList()
        }
      })
    },
    // 添加还款账户
    showBack() {
      this.$refs.backListInfo.addAccountShow = true
    },
    checkBankCode() {
      if (!this.info.bankCode) {
        Dialog.alert({
          title: '系统提示',
          message: '请选择开户银行',
          confirmButtonText: '确定',
          type: 'warning',
        }).then(() => {
          this.$refs.backListInfo.addAccountShow = true
        })
      } else {
        if (!this.$validate.isPhone(this.info.phoneNo)) {
          this.$common.toast('请输入正确的手机号')
          return
        }
        this.dialogFlag = true
      }
    },
    // 获取修改银行卡验证码
    getCode() {
      const { realName, idCard } = this.userInfo
      const { cardNo, bankName, phoneNo, bankCode } = this.info
      const query = {
        realName,
        idNo: idCard,
        bankName,
        bankCode,
        cardNo,
        phoneNo,
        // 0 绑卡 1 改卡
        businessType: 1,
        bindType: this.bindType,
        merchantRoute: this.merchantRoute,
      }

      const loadingToast = Toast.loading({
        message: '发送中...',
        forbidClick: true,
        duration: 0,
      })
      getBankChangeVerifyApi(query).then(() => {
        loadingToast.clear()
        this.countDown = 60
        this.timer = setInterval(() => {
          this.countDown -= 1
          if (this.countDown === 0) {
            clearInterval(this.timer)
            this.codetext = '重新获取'
          }
        }, 1000)
      })
    },
    getBankCodeByBankName(bankName) {},
    // 银行卡ORC 识别
    photoCompress(file, w, objDiv) {
      let that = this
      var ready = new FileReader()
      /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
      ready.readAsDataURL(file.file)
      ready.onload = function () {
        var re = that.result
        that.canvasDataURL(file.content, w, objDiv)
      }
    },
    convertBase64UrlToBlob(urlData) {
      var arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    canvasDataURL(path, obj, callback) {
      var img = new Image()
      img.src = path
      img.onload = function () {
        var that = this
        // 默认按比例压缩
        var w = that.width,
          h = that.height,
          scale = w / h
        w = obj.width || w
        h = obj.height || w / scale
        var quality = 0.2 // 默认图片质量为0.7
        //生成canvas
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        // 创建属性节点
        var anw = document.createAttribute('width')
        anw.nodeValue = w
        var anh = document.createAttribute('height')
        anh.nodeValue = h
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that, 0, 0, w, h)
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        var base64 = canvas.toDataURL('image/jpeg', quality)
        // 回调函数返回base64的值
        callback(base64)
      }
    },
    // 银行卡ORC 识别
    handleAfterRead(file) {
      const loadingToast = Toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
      })
      let that = this
      const query = {
        fileSeqNo: '3',
        fileTypeCode: '3', // 1 是身份证正面 传2 是营业执照 传3 是银行卡 传4 是身份证背面
      }
      var fileSize = parseFloat(parseInt(file.file.size) / 1024 / 1024).toFixed(2)
      if (Number(fileSize) > 10) {
        that.$common.toast('上传图片太大，请重新拍照上传。')
        return
      }
      if (Number(fileSize) > 2 && file.file.type.indexOf('image') != '-1') {
        that.photoCompress(
          file,
          {
            quality: 0.2,
          },
          function (base64Codes) {
            //console.log("压缩后：" + base.length / 1024 + " " + base);
            var bl = that.convertBase64UrlToBlob(base64Codes)
            let newFile = new File([bl], file.file.name, { type: file.file.type.toLowerCase() })
            const formdata = new FormData()
            formdata.append('file', newFile)
            uploadFileNewApi(query, formdata)
              .then((res) => {
                if (res.code === 200) {
                  ocrBankCardNoNewApi({
                    bankCardId: res.data,
                    merchantRoute: this.merchantRoute,
                  })
                    .then((result) => {
                      if (result.data.cardNumber) that.info.cardNo = Decrypt(result.data.cardNumber)
                      that.info.bankName = result.data.bankName
                      that.backList.forEach((item) => {
                        if (item.bankName === that.info.bankName) {
                          that.info.bankCode = item.bankCode
                        }
                      })
                      loadingToast.clear()
                    })
                    .catch(() => {
                      loadingToast.clear()
                    })
                }
              })
              .catch(() => {
                loadingToast.clear()
              })
          }
        )
      } else {
        const formdata = new FormData()
        formdata.append('file', file.file)
        uploadFileNewApi(query, formdata)
          .then((res) => {
            if (res.code === 200) {
              ocrBankCardNoNewApi({
                bankCardId: res.data,
                merchantRoute: this.merchantRoute,
              })
                .then((result) => {
                  if (result.data.cardNumber) this.info.cardNo = Decrypt(result.data.cardNumber)
                  this.info.bankName = result.data.bankName
                  this.backList.forEach((item) => {
                    if (item.bankName === this.info.bankName) {
                      this.info.bankCode = item.bankCode
                    }
                  })

                  loadingToast.clear()
                })
                .catch(() => {
                  loadingToast.clear()
                })
            }
          })
          .catch(() => {
            loadingToast.clear()
          })
      }
      // const formData = new FormData()
      // formData.append('file', file.file)
      // const loadingToast = Toast.loading({
      //   message: '上传中...',
      //   forbidClick: true,
      //   duration: 0,
      // })
    },
    // handleAfterRead(file) {
    //   const formData = new FormData()
    //   formData.append('file', file.file)
    //   const loadingToast = Toast.loading({
    //     message: '上传中...',
    //     forbidClick: true,
    //     duration: 0,
    //   })
    //   const query = {
    //     fileSeqNo: '3',
    //     fileTypeCode: '3', // 1 是身份证正面 传2 是营业执照 传3 是银行卡 传4 是身份证背面
    //   }
    //   uploadFileNewApi(query, formData)
    //     .then(res => {
    //       if (res.code === 200) {
    //         ocrBankCardNoNewApi({
    //           bankCardId: res.data,
    //         })
    //           .then(result => {
    //             console.log(result)
    //             this.info.cardNo = result.data.bankCardNo
    //             this.info.bankName = result.data.bankName
    //             this.info.bankCode =
    //               this.bankList.find(item => item.bankName === result.data.bankName)?.bankCode || ''
    //             console.log(this.info.bankCode)
    //             loadingToast.clear()
    //           })
    //           .catch(() => {
    //             loadingToast.clear()
    //           })
    //       }
    //     })
    //     .catch(() => {
    //       loadingToast.clear()
    //     })
    //   // getBankCardNumberByOrc(formData).then(res => {
    //   //   this.info.cardNo = res.data.bankCardNo
    //   //   this.info.bankName = res.data.bankName
    //   //   loadingToast.clear()
    //   // })
    // },
    // 确认修改银行卡
    handleConfirm() {
      if (this.phoneVerifyCode === '') {
        return Toast.fail('请先输入验证码!')
      } else {
        const { realName, idCard } = this.userInfo
        const { cardNo, bankName, phoneNo, bankCode } = this.info
        const bankSeqNo = this.userBankCardList[0].cardId
        const fourBasic = {
          seqNo: bankSeqNo,
          realName,
          idNo: idCard,
          bankName,
          bankCode,
          cardNo,
          phoneNo,
          phoneVerifyCode: this.phoneVerifyCode,
          businessType: 1,
          bindType: this.bindType,
          merchantRoute: this.merchantRoute,
        }
        const loadingToast = Toast.loading({
          message: '改卡中...',
          forbidClick: true,
          duration: 0,
        })
        updateBankCardApi(fourBasic)
          .then(() => {
            loadingToast.clear()
            this.$toast({
              type: 'success',
              message: '改卡成功',
              duration: 2000,
              onClose: () => {
                this.getUserBindCardList()
                this.dialogFlag = false
                if (this.$route.query.type == 'changeCard1') {
                  window.history.go(-3)
                }
                if (this.$route.query.type == 'changeCard2') {
                  window.history.go(-2)
                }
                // 绑卡成功跳转页面
                this.$router.push('/userCenter')
              },
            })
          })
          .catch(() => {
            this.dialogFlag = false
          })
      }
    },
    // 回显银行名称
    backNameFn(data) {
      this.info.bankName = data.bankName
      this.info.bankCode = data.bankCode
    },
    handleMaskName(str) {
      const len = str.length - 1
      let res = str[0]
      for (let i = 0; i < len; i++) {
        res += '*'
      }
      return res
    },
  },
}
</script>
<style lang="less" scoped>
.borrow-money {
  color: #585c68;
}

.van-cell {
  padding: 0;
  padding: 0.06rem 0 0.12rem;
  border-bottom: 1px solid #e7e7e7;
}
.back {
  margin-top: 0.06rem;
}
.confirm-dialog {
  padding-bottom: 0.4rem;
  .confirm-btn {
    width: 90%;
    margin-top: 0.2rem;
    border-radius: 0.5rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
.close {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  width: 0.4rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  // border: 1px solid #666;
  border-radius: 50%;
  color: #666;
}
</style>

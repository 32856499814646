import request from '@/utils/request'
// 查询未完成的借据
export function getUnCompleteLcAppRecordApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/lcAppl/getUnCompleteLcApplRecord',
    data,
  })
}
// 取消借款
export function cancelApplyApi(data) {
  return request({
    method: 'get',
    url: `/cls/core/app/lcAppl/cancelApply/${data}`,
  })
}
// 完善申请信息
export function updateUserExtApi(data) {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/common/updateUserExt',
    data,
  })
}
// 预选放款路由
export function preMatchApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/doLoan/releaseLoanRoute/preMatch',
    data,
  })
}
// 还款试算
export function trialLoanRepayPlanApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/doLoan/trialLoanRepayPlan',
    data,
  })
}
// 申请信息保存
export function loanApplyApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/doLoan/apply',
    data,
  })
}

// 完善借款信息
export function getProductInitInfoApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/product/getProductInitInfo',
    data,
  })
}
// 获取借款人信息
export function getUserInfoApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/lcAppl/userInfo',
    data,
  })
}
// 提交借款人信息
export function submitUserInfoApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/doLoan/addLoanApplyBasicInfo',
    data,
  })
}
// 借款信息回显接口
export function getLoanInfoApi(data) {
  return request({
    method: 'get',
    url: '/cls/core/loan/getLoanInfo',
    params: data,
  })
}
// 获取短信验证码接口
export function getBankVerifyCodeApi(data) {
  return request({
    method: 'get',
    url: '/cls/core/loan/getBankVerifyCode',
    params: data,
  })
}
// 上传企业凭证
export function uploadImageInfoApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/lcAppl/uploadImageInfo',
    data,
  })
}
// 获取贷款品种初始化信息
export function queryBillDetailApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/lcAppl/queryBillDetail',
    data,
  })
}
// 获取联系人关系
export function queryRelationApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/sComCode/querySComCodeList/RELATION',
    data,
  })
}
// 查看合同
export function previewContractApi(data) {
  return request({
    method: 'get',
    url: `/cls/core/app/contract/file/preView/${data.params}`,
  })
}
// 获取借款刷脸token
export function getBorrowFaceTokenApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/loanFace/faceToken',
    data,
  })
}
// 借款刷脸结果
export function getFaceResultApi(data) {
  return request({
    method: 'post',
    url: '/cls/core/app/loanFace/faceResult',
    data,
  })
}
// 确认提款
export function withdrawConfirmApi(data) {
  return request({
    method: 'get',
    url: `/cls/core/app/doLoan/withdrawConfirm/${data.loanNo}/${data.bankCardNo}`,
  })
}
// 刷脸后 熔断
export function loanApplyCheckApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/doLoan/loanApplyCheck/${data}`,
  })
}
// 查询反欺诈结果
export function queryAntiFraudResultApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/doLoan/queryAntiFraudResult/${data}`,
  })
}
// 上传发票接口
export function uploadInvoiceApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/invoice/uploadInvoice`,
    params: data.loanNo,
    data: data.files,
  })
}
// 发票OCR
export function discriminateInvoiceApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/invoice/discriminateInvoice`,
    params: data,
  })
}
// 获取发票数据
export function listInvoiceDataApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/invoice/listInvoiceData`,
    params: data,
  })
}
// 发票数据修改接口
export function updateInvoiceApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/invoice/updateInvoice`,
    data,
  })
}
// 发票验真
export function checkInvoiceApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/invoice/checkInvoice`,
    params: data,
  })
}
// merchantRoute获取接口
export function merchantRouteApi(data) {
  return request({
    method: 'post',
    url: `/cls/core/app/relation/merchantRoute`,
    data,
  })
}
// 获取省市区地址
export function getAreaTreeApi(data) {
  return request({
    method: 'post',
    url: `/cls/passport-personal/app/area/getAreaTree`,
    data,
  })
}
